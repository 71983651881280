import React from 'react'
import {Deutsch, English, Type, LinkABOUT, Page, Back_To_All_Projects} from '../../components/linksetc.js'
import Layout from '../../components/layout'

import img1 from "../../images/bionic/1.jpg"
import img2 from "../../images/bionic/2.jpg"
import img3 from "../../images/bionic/3.jpg"
import img4 from "../../images/bionic/4.jpg"


// for the scrolldown
var Scroll  = require('react-scroll');
var ScrollLink       = Scroll.Link;
var Element    = Scroll.Element;




export default ({ location }) => (
  <Layout>


      <Page location={location}>




      <div className="project_description">

        <English Language={location.search}>
              <Type>
                    Bionic provides a new kind of personal training: based on electric muscle stimulation.
                    <p/>
                    <a href="http://www.kilokilo.ch" target="_blank">KiloKilo</a> did the digital rebranding of their website, and <LinkABOUT location={location}>I</LinkABOUT> wrote the headlines and optimized the website text for SEO.
                    <p/>
                    <ScrollLink className="ScrollLink" to="test1" smooth={true} duration={1000}>↓</ScrollLink>
              </Type>
        </English>

         <Deutsch Language={location.search}>
              <Type>

                    Bionic bietet ein neuartiges Personal Training an: basierend auf Elektromuskelstimulation.
                    <p/>
                    <a href="http://www.kilokilo.ch" target="_blank">KiloKilo</a> übernahm das digitale Rebranding, <LinkABOUT location={location}>ich</LinkABOUT> schrieb die Headlines und optimierte den Webseitentext für SEO.
                    <p/>
                    <ScrollLink className="ScrollLink" to="test1" smooth={true} duration={1000}>↓</ScrollLink>
              </Type>
        </Deutsch>

    </div>

    <Element name="test1"></Element>


    <img src={img1}/>





      <div className="comment">
        <English Language={location.search}>
              <Type>
              The language uses a technical terminology and sentences with a quick rhythm to reflect both the intensity and the modern technology of the training.
              </Type>
        </English>

         <Deutsch Language={location.search}>
              <Type>

              Die Sprache bedient sich einer technischen Terminologie und Sätze mit schnellem Rhythmus, um die Intensität und moderne Technologie des Trainings wiederzuspiegeln.

              </Type>
        </Deutsch>
        
      </div>


      <img src={img2}/>


      <div className="comment">
        <English Language={location.search}>
              <Type>
              Short headlines support the spacious design and make the content easily digestable.
              </Type>
        </English>

         <Deutsch Language={location.search}>
              <Type>

            Kurze Headlines unterstützen das grosszügige Design und ermöglichen eine schnelle Verarbeitung des Inhalts.

              </Type>
        </Deutsch>
        
      </div>






      <img src={img3}/>


      <div className="comment">
        <English Language={location.search}>
              <Type>
                To improve SEO results, both headings and text feature relevant keywords based on previous research.
              </Type>
        </English>

         <Deutsch Language={location.search}>
              <Type>

              Um SEO Resultate zu verbessern, wurden Titel und Text mit relevanten Keywords versehen.
              </Type>
        </Deutsch>
      </div>


      <img src={img4}/>






      <Back_To_All_Projects location={location} />



      </Page>
    </Layout>

)
